<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2 mb-2">
        <b-row>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="$t('Product Count')" label-for="productCount">
              <validation-provider #default="{ errors }" rules="required" name="productCount">
                <b-form-input id="productCount" v-model="form.productCount" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="$t('Unit Price')" label-for="price">
              <validation-provider #default="{ errors }" rules="required" name="price">
                <b-form-input id="price" v-model="form.price" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="$t('Discounted Price')" label-for="disCountedPrice">
              <validation-provider #default="{ errors }" rules="required" name="disCountedPrice">
                <b-form-input id="disCountedPrice" v-model="form.disCountedPrice" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="demo-inline-spacing">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="saveItem"
                size="sm">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                  size="16"
                />
                {{ $t(saveBtnText) }}
              </b-button>
              <b-button
                variant="warning"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="resetItem()"
                size="sm">
                <feather-icon
                  icon="ResetIcon"
                  class="mr-50"
                  size="16"
                />
                {{ $t('Reset Form') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-table
      small
      class="position-relative"
      :items="productPrices"
      responsive
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No record"
    >
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-right">
          <!-- Update -->
          <feather-icon
            @click="geoPriceItem(data.item)"
            icon="GlobeIcon"
            class="cursor-pointer text-success"
            size="16"
          />
          <feather-icon
            @click="updateItem(data.item)"
            icon="EditIcon"
            class="cursor-pointer mx-1 text-primary"
            size="16"
          />
          <!-- Remove -->
          <feather-icon
            @click="removeItem(data.item.id)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </div>
      </template>
    </b-table>
    <productGeoDetailsModal v-if="modalObject.showModal" :modalObject="modalObject"/>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import Ripple from 'vue-ripple-directive'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

import productGeoDetailsModal from './ProductGeoDetailsModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    productGeoDetailsModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      productPrices: [],
      fields: [
        { key: 'index', label: 'No' },
        { key: 'productCount', label: 'productCount' },
        { key: 'price', label: 'price' },
        { key: 'disCountedPrice', label: 'disCountedPrice' },
        { key: 'actions', class: 'text-right' },
      ],
      form: {
        productId: this.$router.currentRoute.params.id,
        productCount: null,
        price: null,
        disCountedPrice: null,
      },
      saveBtnText: 'Save',

      modalObject: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    fetchPrices() {
      const paramId = this.$router.currentRoute.params.id;
      if (paramId === 0) {
        return;
      }
      this.$store.dispatch('productManagement/fetchProductPrices', { id: paramId })
        .then(response => {
          if (response.statusCode === 200) {
            this.productPrices = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    saveItem() {
      this.$refs.simpleRules.validate().then(success => {
         if (success) {
          const paramId = this.$router.currentRoute.params.id;
          if (paramId === 0) {
            AlertService.error(this, 'Please first save and then try to upload picture.');
            return;
          }
          this.form.productId = paramId;
          this.$store.dispatch('productManagement/saveProductPrice', this.form)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                this.fetchPrices();
                this.resetItem();
              } else {
                AlertService.error(this, response.message)
              }
            });
          }
      });
    },

    updateItem(item) {
      this.form = item;
      this.saveBtnText = 'Update'
    },

    resetItem() {
      this.form = {
        productId: this.$router.currentRoute.params.id,
        productCount: null,
        price: null,
        disCountedPrice: null,
      };

      this.saveBtnText = 'Save'
    },

    geoPriceItem(priceItem) {
      this.modalObject.item = priceItem;
      this.modalObject.showModal = true;
    },

    removeItem(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('productManagement/removeProductPrice', { id })
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: this.$t(response.message) },
                })
                this.fetchPrices();
              } else {
                AlertService.error(this, this.$t(response.message))
              }
            });
      });
    },
  },
  created() {
    this.fetchPrices();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
