<template>
    <b-modal
      ref="modal-product-geo-detail"
      :title="'Geo Pricing'"
      hide-footer
      scrollable
      cancel-variant="outline-secondary"
      @hidden="hiddenModal"
      size="xl"
    >
      <b-card>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="mt-2 mb-2">
            <b-row>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Country Code')" label-for="countryCode">
                  <validation-provider #default="{ errors }" rules="required" name="countryCode">
                    <b-form-input id="countryCode" v-model="form.countryCode" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Country Name')" label-for="countryName">
                  <validation-provider #default="{ errors }" rules="required" name="countryName">
                    <b-form-input id="countryName" v-model="form.countryName" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="form.isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Currency Unit')" label-for="currencyUnit">
                  <validation-provider #default="{ errors }" rules="required" name="currencyUnit">
                    <b-form-input id="currencyUnit" v-model="form.currencyUnit" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Price')" label-for="price">
                  <validation-provider #default="{ errors }" rules="required" name="price">
                    <b-form-input id="price" v-model="form.price" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Discounted Price')" label-for="disCountedPrice">
                  <validation-provider #default="{ errors }" rules="required" name="disCountedPrice">
                    <b-form-input id="disCountedPrice" v-model="form.disCountedPrice" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" lg="3">
                <b-form-group :label="$t('Tax Rate')" label-for="taxRate">
                  <validation-provider #default="{ errors }" rules="required" name="taxRate">
                    <b-form-input id="taxRate" v-model="form.taxRate" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="demo-inline-spacing">
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="saveItem"
                    size="sm">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                      size="16"
                    />
                    {{ $t(saveBtnText) }}
                  </b-button>
                  <b-button
                    variant="warning"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="resetItem()"
                    size="sm">
                    <feather-icon
                      icon="ResetIcon"
                      class="mr-50"
                      size="16"
                    />
                    {{ $t('Reset Form') }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-table
          small
          class="position-relative"
          :items="productGeoDetails"
          responsive
          :fields="fields"
          primary-key="id"
          show-empty
          empty-text="No record"
        >
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- A custom formatted column -->
          <template #cell(countryCode)="data">
            {{ data.item.countryCode+' - '+ data.item.countryName }}
          </template>

          <template #cell(currencyUnit)="data">
            <b-badge
              pill
              variant="info"
            >
              {{ data.item.currencyUnit }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-right">
              <!-- Update -->
              <feather-icon
                @click="updateItem(data.item)"
                icon="EditIcon"
                class="cursor-pointer text-primary"
                size="16"
              />
              <!-- Remove -->
              <feather-icon
                @click="removeItem(data.item.id)"
                icon="Trash2Icon"
                class="cursor-pointer mx-1 text-danger"
                size="16"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import Ripple from 'vue-ripple-directive'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      productGeoDetails: [],
      fields: [
        { key: 'countryCode', label: 'CountryCode' },
        { key: 'countryName', label: 'countryName' },
        { key: 'currencyUnit', label: 'currencyUnit' },
        { key: 'price', label: 'price' },
        { key: 'disCountedPrice', label: 'disCountedPrice' },
        { key: 'taxRate', label: 'taxRate' },
        { key: 'actions' },
      ],
      form: {
        isActive: true,
        countryCode: null,
        countryName: null,
        currencyUnit: null,
        price: 0,
        disCountedPrice: 0,
        taxRate: 0,
      },
      saveBtnText: 'Save',
    }
  },
  methods: {
    fetchGeoDetails() {
      const paramId = this.modalObject.item.id;
      if (paramId === 0) {
        return;
      }
      this.$store.dispatch('productManagement/fetchProductGeoDetails', { id: paramId })
        .then(response => {
          if (response.statusCode === 200) {
            this.productGeoDetails = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    saveItem() {
      this.$refs.simpleRules.validate().then(success => {
         if (success) {
          const paramId = this.modalObject.item.id;
          if (paramId === 0) {
            AlertService.error(this, 'Please first save and then try to upload picture.');
            return;
          }
          this.form.productPriceId = this.modalObject.item.id;
          this.form.productId = this.modalObject.item.productId;
          this.$store.dispatch('productManagement/saveProductGeoDetail', this.form)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                this.fetchGeoDetails();
                this.resetItem();
              } else {
                AlertService.error(this, response.message)
              }
            });
          }
      });
    },

    updateItem(item) {
      this.form = item;
      this.saveBtnText = 'Update'
    },

    removeItem(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('productManagement/removeProductGeoDetail', { id })
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: this.$t(response.message) },
                })
                this.fetchGeoDetails()
              } else {
                AlertService.error(this, this.$t(response.message))
              }
            });
      });
    },

    resetItem() {
      this.form = {
        isActive: true,
        countryCode: null,
        countryName: null,
        currencyUnit: null,
        unitPrice: 0,
        disCountedPrice: 0,
        taxRate: 0,
      };

      this.saveBtnText = 'Save'
    },

    initialModal() {
      this.$refs['modal-product-geo-detail'].show();
      this.fetchGeoDetails();
    },
    hiddenModal() {
      this.$refs['modal-product-geo-detail'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
