var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8","lg":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Code'),"label-for":"code"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","state":errors.length > 0 ? false:null},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Raw Data Upload'),"label-for":"rowDataUpload"}},[_c('validation-provider',{attrs:{"rules":"required","name":"rowDataUpload"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rowDataUpload","state":errors.length > 0 ? false:null},model:{value:(_vm.rowDataUpload),callback:function ($$v) {_vm.rowDataUpload=$$v},expression:"rowDataUpload"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"label":"","label-for":"contact-options","label-class":"mb-1"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-primary",model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Display Name'),"label-for":"displayName"}},[_c('validation-provider',{attrs:{"rules":"required","name":"displayName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"displayName","state":errors.length > 0 ? false:null},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Unit Price'),"label-for":"unitPrice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"unitPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"unitPrice","state":errors.length > 0 ? false:null},model:{value:(_vm.unitPrice),callback:function ($$v) {_vm.unitPrice=$$v},expression:"unitPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Discount Price'),"label-for":"disCountedPrice"}},[_c('validation-provider',{attrs:{"rules":"required","name":"disCountedPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"disCountedPrice","state":errors.length > 0 ? false:null},model:{value:(_vm.disCountedPrice),callback:function ($$v) {_vm.disCountedPrice=$$v},expression:"disCountedPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Tax Rate'),"label-for":"taxRate"}},[_c('validation-provider',{attrs:{"rules":"required","name":"taxRate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"taxRate","state":errors.length > 0 ? false:null},model:{value:(_vm.taxRate),callback:function ($$v) {_vm.taxRate=$$v},expression:"taxRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Currency Unit'),"label-for":"currencyUnit"}},[_c('validation-provider',{attrs:{"rules":"required","name":"currencyUnit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"currencyUnit","state":errors.length > 0 ? false:null},model:{value:(_vm.currencyUnit),callback:function ($$v) {_vm.currencyUnit=$$v},expression:"currencyUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Type'),"label-for":"productType"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productTypeOptions,"reduce":function (val) { return val.value; },"clearable":false,"state":errors.length > 0 ? false:null,"input-id":"productType","placeholder":"Select Type"},model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=$$v},expression:"productType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Picture')))]),_c('div',{staticClass:"bg-light-primary rounded-top text-center mt-1 banner-img-box"},[_c('b-img',{staticClass:"responsive",attrs:{"src":_vm.pictureUrl}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-primary btn-image-update"},on:{"click":function($event){return _vm.openUpload()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PaperclipIcon"}}),_c('span',[_vm._v(_vm._s(!_vm.pictureUrl? _vm.$t('Add') : _vm.$t('Change')))])],1)],1)])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveItem.bind(this, true),"onClickSave":_vm.saveItem.bind(this, false)}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }